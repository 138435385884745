.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.styles{
  font-family: sans-serif;
  text-align: center;
}

.inside-styles {
  padding: 20px;
  position: absolute;
  top: 57%;
  left: 70%;
  transform: translate(-50%,-50%);
  opacity: 0.8;
  border-radius: 10px;
  font-weight: bold;
  font-size: 50px;
  color: black;
}

.home {
  margin-top: 102px;
}

.home-image {/*親div*/
  position: relative;/*相対配置*/
}

.youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.home-image .home-image-1 {
  position: absolute;
  color: black;/*文字は白に*/
  font-weight: bold; /*太字に*/
  font-size: 50px;/*サイズ2倍*/
  font-family :Quicksand, sans-serif;/*Google Font*/
  top: 47%;
  left: 72%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  margin:0;
  padding:0;
  line-height: 120px;
  width: 800px;
}

.home-image-sp {/*親div*/
  display: none;
}

.home-image-sp .home-image-1-sp {
  position: absolute;
  color: black;/*文字は白に*/
  font-weight: bold; /*太字に*/
  font-size: 50px;/*サイズ2倍*/
  font-family :Quicksand, sans-serif;/*Google Font*/
  top: 47%;
  left: 72%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  margin:0;
  padding:0;
  line-height: 120px;
  width: 800px;
}


.home-image img {
  width: 80%;
}

.an2-fadeup {
  position:fixed;
  right:5px;
  bottom:5px;
  animation-name: fadeup;
  animation-duration: 1s;
  animation-iteration-count: 1;
  opacity:0.9;
  background: linear-gradient(-150deg, #0575e6, #00f260);
  text-align: center;
  width: 400px;
  height: 130px;
  z-index:10;
}
@keyframes fadeup {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.an2-fadeup .fadeup-text {
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 15px 0 25px 0;
}

.an2-fadeup .fadeup-press-btn-blue {
  color: black;
  background-color: white;
  border-radius: 10px;
  font-size: 20px;
  padding: 15px 70px;
  text-decoration: none;
  font-weight: bold;
}

.an2-fadeup .fadeup-text-2 {
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: 20px 0 20px 0;
}

.notfound{
  color: black;/*文字は白に*/
  font-weight: bold; /*太字に*/
  font-size: 2em;
  padding: 10px;
}

.fade-in {
  position:fixed;
  right:50px;
  bottom:50px;
  transition:1s;
  opacity:0.9;
}

/**
 * 共通部分
 */
body {
  color: #333333;
  box-sizing: border-box;
  line-height: 1.5;
}

main {
  margin-top: 100px;
}

img {
  width: 100%;
}

a {
  color: #333333;
  text-decoration: none;
}

li {
  list-style: none;
}

.container {
  width: 96%;
  max-width: 1180px;
  margin: 0 auto;
}

.service-container {
  width: 80%;
  max-width: 1180px;
  margin: 0 auto;
}

section {
  margin: 30px 0;
  padding: 30px 0;
}

.section-dx {
  margin: 30px 0;
  padding: 30px 0;
}

.border-bottom {
  border-bottom: 1px solid #333333;
}

.font-bold {
  font-weight: bold;
}

.section-title-flex {
  display: flex;
  padding-bottom: 30px;
}

.section-title-flex .box {
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: center;
}

.section-title-flex .triangle {
  width: 50px;
  height: 50px;
}

.section-title-flex .section-title h2 {
  font-size: 30px;
  font-weight: normal;
}

/**
 * ヘッダー
 */
header {
  position: fixed;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #ccc;
  background: #fff;
  z-index: 1;
}

.top-content {
  background: #00bfff;
  color: #fff;
  display: flex;
  padding: 5px 10px;
  font-size: 14px;
}

.top-content h3 {
  font-weight: normal;
  margin-left: 10px;
  line-height: 34px;
}

.top-content .links {
  margin-left: auto;
  display: flex;
  padding: 5px 10px;
}

.top-content .links a {
  color: #fff;
  margin-left: 10px;
}

header .header-nav {
  display: flex;
  align-items: center;
  font-size: 15px;
  width: 80%;
  margin: 0 auto;
}

header .header-nav ul.main-menu {
  align-items: center;
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 3%;
}

header .header-nav ul.main-menu li.logo {
  width: 200px;
  padding-top: 7px;
  margin-right: 500px;
}

header .header-nav ul.main-menu li {
  height: 60px;
}

header .header-nav ul.main-menu li a {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 18px;
}

header .header-nav ul.right-menu {
  margin-left: auto;
}

header .header-nav ul.right-menu li a {
  margin-right: 20px;
}

header .header-nav ul.right-menu li {
  position: relative;
  float: left;
  left: 20px;
}

header .header-nav ul.right-menu .register-btn {
  color: #fff;
  background: #ffa500;
  border-radius: 30px;
  padding: 10px 24px;
}

header .header-nav .sp-menu,
header .header-nav .sp-logo {
  display: none;
}

.top-content-dx {
  background: #00bfff;
  color: #fff;
  display: flex;
  padding: 0 10px;
  font-size: 20px;
  font-weight: bold;
}

.top-content-dx .links {
  margin-left: auto;
  display: flex;
  padding: 5px 10px;
}

.top-content-dx .links a {
  color: #fff;
  margin-left: 10px;
}

.top-content-dx .links img {
  width: 20px;
  padding-right: 10px;
}

/**
 * aboutus
 */
.about-us-wrapper {
  text-align: center;
}

.about-us-wrapper .items {
  display: flex;
  justify-content: space-around;
}

.about-us-wrapper .items .item {
  width: 21.5%;
}

.about-us-wrapper .items .item .about-us-title {
  font-weight: bold;
  font-size: 22px;
  padding-bottom: 3px;
}

.about-us-wrapper .items .item .about-us-text {
  font-size: 16px;
  padding-bottom: 15px;
  margin-top: 10px;
}

.about-us-wrapper .items .item .about-us-btn {
  display: inline-block;
  border: 1px solid #00bfff;
  border-radius: 30px;
  padding: 5px 24px;
}

.about-us-wrapper .items .item .about-us-btn a {
  color: #00bfff;
  font-size: 14px;
  text-decoration: none;
  line-height: 24px;
}

.about-us-wrapper .items .item .about-us-btn:hover {
  display: inline-block;
  border: 1px solid #00bfff;
  background-color: #00bfff;
  border-radius: 30px;
  padding: 5px 24px;
}

.about-us-wrapper .items .item .about-us-btn:hover a {
  color: white;
  font-size: 14px;
  text-decoration: none;
  line-height: 24px;
}


/**
 * FAQ
 */
.faq-wrapper .q-and-a-content #acMenu .items {
  margin-bottom: 50px;
}

.faq-wrapper .q-and-a-content #acMenu .items .faq-title {
  text-align: center;
  font-size: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.4);
}

.faq-wrapper .q-and-a-content #acMenu .items .ac-item {
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid rgba(128, 128, 128, 0.4);
  cursor: pointer;
}

.faq-wrapper .q-and-a-content #acMenu .items .ac-item:after {
  position: absolute;
  display: block;
  content: "";
  background-color: #20b2aa;
  height: 40%;
  width: 4px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
}

.faq-wrapper .q-and-a-content #acMenu .items .ac-item .ac-content {
  margin: 0 auto;
  display: block;
  width: 90%;
}

.faq-wrapper .q-and-a-content #acMenu .items .ac-item .ac-content dt {
  font-size: 16px;
  padding: 20px 0;
  line-height: 22px;
  position: relative;
  font-weight: bold;
}

.faq-wrapper .q-and-a-content #acMenu .items .ac-item .ac-content dt i {
  position: absolute;
  right: 0;
}

.faq-wrapper .q-and-a-content #acMenu .items .ac-item .ac-content .q-and-a-answer {
  width: 90%;
  padding: 0 0 15px 0;
}

.faq-wrapper .q-and-a-content #acMenu .items .ac-item .ac-content .faq-answer-text {
  font-size: 14px;
}

.ac-check {
  display: none;
}

.ac-label {
  cursor: pointer;
}

.ac-label:after{
  content:"";
  display:block;
  width:12px;
  height:12px;
  border-top: #222 2px solid;
  border-right: #222 2px solid;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  position:absolute;
  right: 2%;
  top: 0;
  bottom: 15%;
  margin: auto;
}

.ac-check:checked + .ac-label:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ac-hidden-content {
  display:none;
}

.ac-check:checked + .ac-label + .ac-hidden-content {
  height: 40px;
  padding: 10px;
  display: block;
}

/**
 * サポート
 */
.support-wrapper .support-sub-title {
  text-align: center;
  color: #00bfff;
  font-size: 30px;
  padding-bottom: 20px;
}

.support-wrapper .support-text {
  text-align: center;
  font-size: 14px;
  margin: 0 auto 30px auto;
  width: 80%;
}

.support-wrapper .items {
  display: flex;
  justify-content: center;
  position: relative;
}

.support-wrapper .items:after {
  position: absolute;
  content: "";
  background-color: #adabab;
  height: 100%;
  width: 1px;
  margin: auto;
}

.support-wrapper .items .item .support-img {
  width: 80%;
  display: block;
  margin: 0 auto;
  padding: 0 40px;
}

.support-wrapper .items .item .item-text {
  color: #00bfff;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

/**
 * できること
 */
.can-do-wrapper .can-do-contents .can-do-items {
  display: flex;
  justify-content: space-around;
}

.can-do-wrapper .can-do-contents .can-do-items .can-do-item {
  width: 25%;
}

.can-do-wrapper .can-do-contents .can-do-items .can-do-item .can-do-img {
  width: 80%;
  display: block;
  margin: 0 auto;
  padding-bottom: 10px;
}

.can-do-wrapper .can-do-contents .can-do-items .can-do-item .can-do-item-title {
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
  color: #00bfff;
  padding-bottom: 20px;
}

.can-do-wrapper .can-do-contents .can-do-items .can-do-item .can-do-item-text {
  text-align-last: left;
  font-size: 14px;
  line-height: 24px;
}

/**
 * フッター
 */
footer {
  background-color: #e1eaef;
}

footer .footer-top .footer-top-items {
  width: 50%;
  padding: 30px;
  margin-left: 100px;
}

footer .footer-top .footer-top-items .footer-top-title {
  font-weight: normal;
  padding-bottom: 10px;
}

footer .footer-top .footer-top-items .footer-top-item {
  padding-left: 0;
}

footer .footer-top .footer-top-items .footer-top-item li {
  font-size: 14px;
  text-align: left;
}

footer .footer-bottom {
  background-color: #00bfff;
  padding: 20px 0;
}

footer .footer-bottom .footer-bottom-items {
  width: 45%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
}

footer .footer-bottom .footer-bottom-items .footer-bottom-item {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
}

footer .footer-bottom .copy-right {
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}

/**
 * フッター詳細
 */
.footer-detail-wrapper {
  background-color: rgba(128, 128, 128, 0.2);
  padding: 20px 0;
}

.footer-detail-wrapper .footer-detail-contents .footer-detail-title {
  padding-bottom: 40px;
}

.footer-detail-wrapper .footer-detail-contents .footer-detail-items {
  display: flex;
}

.footer-detail-wrapper .footer-detail-contents .footer-detail-items .footer-detail-sub-title {
  font-weight: bold;
  width: 20%;
  display: inline-block;
  font-size: 14px;
}

.footer-detail-wrapper .footer-detail-contents .footer-detail-items .footer-detail-item {
  padding-bottom: 30px;
  width: 80%;
}

.footer-detail-wrapper .footer-detail-contents .footer-detail-items .footer-detail-item .footer-detail-item-text {
  font-size: 14px;
  padding: 1px;
  display: inline-block;
  line-height: 30px;
}

/* page2 */
.pg2-title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  position: relative;
  margin-bottom: 60px;
}

.pg2-title:after {
  position: absolute;
  content: "";
  height: 3px;
  width: 60px;
  border-radius: 10px;
  background-color: #333333;
  top: 0;
  left: 0;
  right: 0;
  bottom: -80px;
  margin: auto;
}

.pg2-more {
  text-align: center;
  padding: 5px 0 0 0;
}

/**
 * プレスリリース
 */
.press-wrapper .press-notice {
  font-size: 12px;
  background-color: rgba(128, 128, 128, 0.1);
  display: inline-block;
  padding: 1px 7px;
  border-radius: 4px;
  margin-bottom: 5px;
  color: rgba(51, 51, 51, 0.8);
}

.press-wrapper .press-contents .press-contents-title {
  font-size: 31px;
  font-weight: bold;
  line-height: 40px;
  padding-bottom: 2px;
}

.press-wrapper .press-contents .press-contents-title-contact-complete {
  font-size: 31px;
  font-weight: bold;
  line-height: 40px;
  padding-bottom: 2px;
  text-align: center;
}

.press-wrapper .press-contents .press-contents-title-contact-description {
  font-size: 25px;
  font-weight: normal;
  line-height: 35px;
  padding-bottom: 2px;
}

.service-content{
  display: block;
  flex-direction: column;
  margin: 0 auto 7px auto;
  max-width: 800px;
  padding: 10px 10px 10px 10px;
}

.service-confirm-content {
  padding: 5px 5px 20px 2px;
  margin: auto;
}

.service-confirm-top {
  margin: 0 auto 0 auto;
  max-width: 800px;
  padding: 0 0 0 0;
  font-size: 22px;
  color:black;
}

.service-confirm-title {
  margin: 0 auto 0 auto;
  max-width: 800px;
  font-size: 31px;
  font-weight: bold;
  line-height: 40px;
  padding-bottom: 2px;
  color:black;
}

.press-wrapper .press-contents .image {
  text-align: center;
  width: 60%;
  margin: 15px auto;
 }

.press-wrapper .press-contents .press-date {
  font-size: 12px;
  margin-bottom: 10px;
}

.press-wrapper .press-contents .press-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.press-wrapper .press-contents .press-item .press-item-left {
  width: 60%;
  font-size: 16px;
}

.press-wrapper .press-contents .press-item .press-item-right {
  width: 35%;
}

.press-wrapper .press-contents .press-btn {
  color: #00bfff;
  font-size: 14px;
  padding: 10px 30px;
  border: 1px solid #00bfff;
  border-radius: 20px;
  text-decoration: none;
}

.press-wrapper .press-contents .press-btn:hover {
  color: white;
  font-size: 14px;
  padding: 10px 30px;
  border: 1px solid #00bfff;
  background-color: #00bfff;
  border-radius: 20px;
  text-decoration: none;
}

.press-btn-blue {
  color: white;
  background-color: #00bfff;
  border-radius: 20px;
  font-size: 16px;
  padding: 12px 30px;
  text-decoration: none;
  font-weight: bold;
}

.press-btn-blue:hover {
  color: white;
  background-color: lightseagreen;
  border-radius: 20px;
  font-size: 16px;
  padding: 12px 30px;
  text-decoration: none;
  font-weight: bold;
}

/**
 * 会社概要
 */
.about-company-wrapper .about-company-header {
  margin-bottom: 50px;
}

.about-company-wrapper .about-company-header ul {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.about-company-wrapper .about-company-header ul li {
  position: relative;
  padding: 0 20px;
}

.about-company-wrapper .about-company-header ul li a {
  text-decoration: none;
}

.about-company-wrapper .about-company-header ul li:after {
  position: absolute;
  display: block;
  content: "";
  background: gray;
  height: 100%;
  width: 1px;
  top: 0;
  right: 0;
}

.about-company-wrapper .about-company-header ul li:last-child:after {
  height: 0;
  width: 0;
}

.about-company-wrapper .about-company-contents .items {
  display: flex;
  justify-content: center;
  padding: 20px 0px 20px 150px;
}

.about-company-wrapper .about-company-contents .items .box {
  width: 48%;
}

.about-company-wrapper .about-company-contents .items .box .item {
  padding-bottom: 40px;
}

.about-company-wrapper .about-company-contents .item .about-company-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.about-company-wrapper .about-company-contents .item .privacy {
  display: flex;
  justify-content: space-between;
}

.about-company-wrapper .about-company-contents .item .privacy .about-company-text {
  width: 70%;
}

.about-company-wrapper .about-company-contents .item .privacy .privacy-img {
  width: 20%;
}


/**
 * 各業界
 */
.business-wrapper {
  background-color: rgba(128, 128, 128, 0.1);
}

.business-wrapper .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.business-wrapper .items .item {
  width: 31.3%;
  border: 1px solid rgba(128, 128, 128, 0.4);
  background-color: #fff;
  padding: 10px 25px 25px 25px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.business-wrapper .items .item .business-img {
  display: block;
  margin: 0 auto;
  width: 70%;
}

.business-wrapper .items .item .business-title {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding-bottom: 10px;
}

.business-wrapper .items .item .text-wrapper {
  display: inline-block;
}

.business-wrapper .items .item .text-wrapper .business-text {
  color: #fff;
  background-color: #00bfff;
  line-height: 30px;
  border-radius: 5px;
  font-size: 12px;
  padding: 7px;
  display: inline;
}

/**
 * service
 */
.service-wrapper {
  background-color: rgba(128, 128, 128, 0.1);
}

.service-wrapper .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service-wrapper .items .item {
  width: 45%;
  border: 1px solid rgba(128, 128, 128, 0.4);
  background-color: #fff;
  padding: 10px 25px 25px 25px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.service-wrapper .items .item .service-img {
  display: block;
  margin: 0 auto;
  width: 70%;
}

.service-wrapper .items .item .service-img-left {
  display: block;
  margin: 0 auto;
  width: 70%;
  margin-bottom: 33px;
}

.service-wrapper .items .item .service-title {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding: 10px;
}

.service-wrapper .items .item .text-wrapper {
  display: inline-block;
}


.service-wrapper-dx-solution {
  background-color: white;
  padding: 50px 0 5px 0 ;
}

.service-wrapper-dx-solution .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service-wrapper-dx-solution .items .item-left {
  width: 40%;
  padding: 30px 0 25px 50px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.service-wrapper-dx-solution .items .item-right {
  width: 50%;
  padding: 10px 0 25px 0;
  border-radius: 5px;
  margin-bottom: 30px;
}

.service-wrapper-dx-solution .items .item-right .service-img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.service-wrapper-dx-solution .items .item-left .service-title-top {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  padding: 10px;
}

.service-wrapper-dx-solution .items .item-left .service-title {
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
  line-height: 30px;
}

.service-wrapper-dx-solution .items .item-left .text-wrapper {
  display: inline-block;
}

.business-wrapper .items .item .text-wrapper .business-text {
  color: #fff;
  background-color: #00bfff;
  line-height: 30px;
  border-radius: 5px;
  font-size: 12px;
  padding: 7px;
  display: inline;
}


.service-wrapper-dx-main {
  background-color: rgba(128, 128, 128, 0.1);
  padding: 30px;
}

.service-wrapper-dx-main .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service-wrapper-dx-main .items .item {
  width: 45%;
  border: 1px solid rgba(128, 128, 128, 0.4);
  background-color: #fff;
  padding: 10px 25px 25px 25px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.service-wrapper-dx-main .items .item .service-img {
  display: block;
  margin: 0 auto;
  width: 30%;
}

.service-wrapper-dx-main .items .item .service-img-dx {
  display: block;
  margin: 0 auto;
  width: 20%;
}

.service-wrapper-dx-main .items .item .service-img-marketing {
  display: block;
  margin: 0 auto;
  width: 15%;
}

.service-wrapper-dx-main .items .item .service-title {
  font-weight: bold;
  font-size: 16px;
  padding: 20px 50px 20px 50px;
  line-height: 25px;
}

.service-wrapper-dx-main .items .item .service-title-top {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  padding: 10px;
}

.service-wrapper-dx-main .items .item .text-wrapper {
  display: inline-block;
}

/**
 * service-company
 */
.service-company-wrapper {
  background-color: white;
  padding: 30px 0;
}

.service-company-wrapper .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service-company-wrapper .items .item {
  width: 32%;
  border: 1px solid rgba(128, 128, 128, 0.4);
  background-color: #fff;
  padding: 10px 25px 0 25px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.service-company-wrapper .items .item .service-img {
  display: block;
  margin: 0 auto;
  width: 100%;
}


/**
 * 機能
 */
.function-wrapper {
  background-color: rgba(128, 128, 128, 0.1);
}

.function-wrapper .items {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.function-wrapper .items .item {
  border: 2px solid rgba(128, 128, 128, 0.3);
  width: 23%;
  padding: 20px;
  margin: 4px;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.function-wrapper .items .item:hover {
  border-color: #00bfff;
}

.function-wrapper .items .item .function-inside {
  border: 2px solid #fff;
}

.function-wrapper .items .item .function-img {
  width: 80%;
  display: block;
  margin: 0 auto;
}

.function-wrapper .items .item .function-title {
  color: #00bfff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.function-wrapper .items .item-2col {
  width: 48%;
}

.function-wrapper .function-bottom-title {
  font-size: 18px;
  padding-bottom: 20px;
}

.function-wrapper .function-bottom-text {
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 30px;
}

.function-wrapper .function-btn {
  display: block;
  text-align: center;
}

.function-wrapper .function-btn a {
  color: #fff;
  background-color: #00bfff;
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 30px;
}

/**
 * 電話
 */
 .tel-wrapper {
  text-align: center;
  color: #ffffff;
  padding: 50px 0;
  background: linear-gradient(135deg, #0575e6, #61BCD1);
}

.tel-wrapper .tel-head {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.tel-wrapper .tel-number {
  font-size: 45px;
  margin-bottom: 10px;
  font-family: 'PT Sans Narrow', sans-serif;
}

.tel-wrapper .tel-number img {
  width: 30px;
  padding-right: 10px;
}

.tel-wrapper .tel-time {
  font-size: 14px;
}

/**
 * 導入事例
 */
.example-wrapper .example-contents .items {
  display: flex;
  justify-content: space-evenly;
}

.example-wrapper .example-contents .items .item {
  border: 1px solid rgba(128, 128, 128, 0.4);
  width: 29%;
  border-radius: 5px;
}

.example-wrapper .example-contents .items .item .example-img {
  position: relative;
}


.example-wrapper .example-contents .items .item .example-img .detail-btn {
  position: absolute;
  display: block;
  color: #fff;
  padding: 5px 18px;
  border: 1px solid #fff;
  font-size: 14px;
  border-radius: 20px;
  background-color: transparent;
  bottom: 15px;
  right: 10px;
}

.example-wrapper .example-contents .items .item .example-text-contents {
  padding: 10px 20px;
  letter-spacing: 1.3px;
}

.example-wrapper .example-contents .items .item .example-text-contents .example-title {
  font-weight: bold;
  padding-bottom: 15px;
  font-size: 14px;
  letter-spacing: 0.2px;
}

.example-wrapper .example-contents .items .item .example-text-contents .example-type {
  font-size: 14px;
  padding-bottom: 5px;
}

.example-wrapper .example-contents .items .item .example-text-contents .example-type .example-type-blue {
  color: #ffffff;
  background-color: #00bfff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #00bfff;
  font-size: 12px;
}

.example-wrapper .example-contents .items .item .example-text-contents .example-reserv {
  font-size: 14px;
  padding-bottom: 5px;
}

.example-wrapper .example-contents .items .item .example-text-contents .example-reserv .example-reserv-white {
  padding: 5px;
  border: 1px solid rgba(128, 128, 128, 0.4);
  border-radius: 5px;
  font-size: 12px;
}

.example-wrapper .example-contents .items .item .example-text-contents .example-name {
  color: gray;
  font-size: 13px;
}

/**
 * 費用
 */
.price-wrapper .price-contents .price-recommend-item {
  display: block;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.price-wrapper .price-contents .price-recommend-item .price-recommend {
  color: #ffa500;
  padding: 7px 0;
  margin-bottom: 30px;
  width: 30%;
  font-weight: bold;
  border: 2px solid #FFA500;
  display: inline-block;
}

.price-wrapper .price-contents .price-recommend-item .price-recommend:before {
  content: "";
  position: absolute;
  bottom: 22px;
  left: 50%;
  margin-left: -9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #fff transparent;
  z-index: 0;
}

.price-wrapper .price-contents .price-recommend-item .price-recommend:after {
  border-style: solid;
  border-width: 11px 11px 0 11px;
  border-color: orange transparent transparent transparent;
  content: "";
  position: absolute;
  bottom: 19px;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  z-index: -1;
}

.price-wrapper .price-contents .items {
  display: flex;
  justify-content: space-around;
  margin-top: -10px;
}

.price-wrapper .price-contents .items .item {
  width: 30%;
  border-radius: 5px;
  padding: 30px 20px;
}

.price-wrapper .price-contents .items .item .price-title {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 10px;
}

.price-wrapper .price-contents .items .item .price-price {
  font-size: 14px;
  text-align: center;
  padding-bottom: 20px;
}

.price-wrapper .price-contents .items .item .price-price .price-yen {
  font-weight: bold;
  font-size: 30px;
  padding: 0 10px;
  letter-spacing: 2px;
}

.price-wrapper .price-contents .items .item .price-sub-title {
  text-align: center;
  font-weight: bold;
  padding-bottom: 30px;
}

.price-wrapper .price-contents .items .item .price-btn {
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;
}

.price-wrapper .price-contents .items .item .price-btn a {
  color: #fff;
  background-color: #00bfff;
  padding: 10px 40px;
  border-radius: 20px;
  font-size: 14px;
  text-decoration: none;
}

.price-wrapper .price-contents .items .item .price-function {
  text-align: center;
  position: relative;
  font-size: 14px;
}

.price-wrapper .price-contents .items .item .price-function:before {
  position: absolute;
  display: block;
  content: "";
  height: 1px;
  width: 70px;
  background-color: rgba(128, 128, 128, 0.5);
  top: 50%;
}

.price-wrapper .price-contents .items .item .price-function:after {
  position: absolute;
  display: block;
  content: "";
  height: 1px;
  width: 70px;
  background-color: rgba(128, 128, 128, 0.5);
  top: 50%;
  right: 0;
}

.price-wrapper .price-contents .items .item .price-function-items {
  padding: 20px 3px;
}

.price-wrapper .price-contents .items .item .price-function-items li {
  line-height: 36px;
  position: relative;
  left: 19px;
}

.price-wrapper .price-contents .items .item .price-function-items li::before,
.price-wrapper .price-contents .items .item .price-function-items li::after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: -20px;
  width: 7px;
  height: 14px;
  transform: rotate(40deg);
  border-bottom: 3px solid #222;
  border-right: 3px solid #222;
}

.price-wrapper .price-contents .items .item .price-function-items li.active::before,
.price-wrapper .price-contents .items .item .price-function-items li.active::after {
  border-bottom: 3px solid #32aee5;
  border-right: 3px solid #32aee5;
}

.price-wrapper .price-contents .items .item .price-function-items .price-function-blue {
  color: #00bfff;
  font-weight: bold;
}

.price-wrapper .price-contents .items .price-gray {
  border: 2px solid rgba(128, 128, 128, 0.3);
}

.price-wrapper .price-contents .items .price-blue {
  border: 2px solid #00bfff;
}

/**
 * 予約管理
 */
.reserv-manage-wrapper {
  padding: 50px 0;
  background-color: rgba(128, 128, 128, 0.1);
}

.reserv-manage-wrapper .reserv-manage-contents .reserv-manage-title {
  text-align: center;
  font-size: 30px;
  color: #00bfff;
}

.reserv-manage-wrapper .reserv-manage-contents .reserv-manage-img {
  width: 15%;
  display: block;
  margin: 0 auto 50px auto;
}

.reserv-manage-wrapper .items {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.reserv-manage-wrapper .items:after {
  position: absolute;
  display: block;
  content: "";
  background-color: rgba(128, 128, 128, 0.2);
  height: 1px;
  width: 100%;
  top: -30px;
}

.reserv-manage-wrapper .items .item {
  width: 32%;
  background-color: rgba(128, 128, 128, 0.1);
  padding: 35px 30px 70px 30px;
  border-radius: 5px;
}

.reserv-manage-wrapper .items .item .item-title {
  font-size: 15px;
  padding-bottom: 30px;
}

.reserv-manage-wrapper .items .item .item-img {
  width: 100%;
  display: block;
  margin: 0 auto 10px 0;
  position: relative;
}

.reserv-manage-wrapper .items .item .item-img .zoom-btn {
  position: absolute;
  display: block;
  color: #fff;
  background-color: #000000;
  padding: 0 8px;
  bottom: 5px;
  right: 0;
}

.reserv-manage-wrapper .items .item .reserv-manage-text {
  font-size: 14px;
  line-height: 26px;
}

@media screen and (max-width: 1350px) {

  .home-image .home-image-1 {
    font-size: 2.5em; /*サイズ2倍*/
    top: 49%;
    left: 77%;
    line-height: 80px;
  }

  header .header-nav ul.main-menu li.logo {
    margin-right: 300px;
  }

}

@media screen and (max-width: 1100px) {

  .home-image .home-image-1 {
    font-size: 2.0em; /*サイズ2倍*/
    top: 49%;
    left: 88%;
    line-height: 80px;
  }

  header .header-nav ul.main-menu li.logo {
    margin-right: 70px;
  }
}

@media screen and (max-width: 800px) {
  .home-image-sp {/*親div*/
    display: block;
  }

  .home-image {/*親div*/
    display: none;
  }

  .home-image-sp .home-image-1-sp {
    font-size: 20px; /*サイズ2倍*/
    top: 32%;
    left: 100%;
    line-height: 60px;
    width: 500px;
  }

  /**
   * ヘッダー
   */
  header .header-nav {
    width: 100%;
  }

  header .header-nav ul.main-menu li.logo {
    width: 130px;
    padding-top: 15px;
  }

  header .header-nav ul.main-menu li a {
    font-size: 12px;
    padding: 0 4px;
  }

  header .header-nav ul.right-menu li a {
    margin-right: 0;
  }

  header .header-nav ul.right-menu li {
    position: relative;
    float: left;
    left: 0;
  }

  header .header-nav ul.main-menu li.logo {
    padding: 11px 0 0 0;
    width: 160px;
    margin-right: 100px;
  }
}

@media screen and (max-width: 480px) {
  .tel-wrapper .tel-head {
    font-size: 16px;
    font-weight: bold;
  }

  .tel-wrapper .tel-number {
    font-size: 30px;
    font-family: 'PT Sans Narrow', sans-serif;
  }

  .tel-wrapper .tel-number img {
    width: 25px;
    padding-right: 12px;
  }
  
  .an2-fadeup {
    right:0;
    bottom:0;
    width: 100%;
  }

  .service-wrapper .items .item .service-img-left {
    margin-bottom: 3px;
  }

  .home-image .home-image-1 {
    font-size: 1.0em; /*サイズ2倍*/
    top: 49%;
    left: 75%;
    line-height: 80px;
  }

  section {
    margin: 15px 0;
    padding: 15px 0;
  }

  /**
   * ヘッダー
   */
  header .header-nav {
    width: 100%;
  }

  header .header-nav ul.main-menu li a {
    font-size: 12px;
    padding: 0 4px;
  }

  header .header-nav ul.right-menu li a {
    margin-right: 0;
  }

  header .header-nav ul.right-menu li {
    position: relative;
    float: left;
    left: 0;
  }

  header .header-nav ul.main-menu li.logo {
    padding: 11px 0 0 0;
    width: 160px;
    margin-right: 30px;
  }


  /**
   * aboutus
   */
  .about-us-wrapper .items {
    flex-direction: column;
  }

  .about-us-wrapper .items .item {
    width: 90%;
    margin: 20px auto;
  }

  /**
   * FAQ
   */
  .faq-wrapper .q-and-a-content #acMenu .items .ac-item .ac-content dt {
    font-size: 14px;
    padding: 20px 15px 20px 0;
  }

  .faq-wrapper .q-and-a-content #acMenu .items .ac-item .ac-content dt i {
    right: -10px;
  }

  /**
   * サポート
   */
  .support-wrapper .support-sub-title,
  .section-title-flex .section-title h2 {
    font-size: 22px;
  }

  .support-wrapper .support-text {
    width: 90%;
  }

  .support-wrapper .items:after {
    width: 100%;
    height: 1px;
  }

  .support-wrapper .items {
    flex-direction: column;
  }

  .support-wrapper .items .item {
    height: 200px;
    margin-top: 20px;
  }

  /**
   * できること
   */
  .can-do-wrapper .can-do-contents .can-do-items {
    flex-direction: column;
  }

  .can-do-wrapper .can-do-contents .can-do-items .can-do-item {
    width: 90%;
    margin: 0 auto;
  }

  /**
   * フッター
   */
  footer .footer-top .footer-top-items {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  footer .footer-top .footer-top-items .footer-top-item li {
    text-align: center;
  }

  footer .footer-bottom {
    padding-bottom: 135px;
  }

  footer .footer-bottom .footer-bottom-items {
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    text-align: center;
  }

  footer .footer-bottom .footer-bottom-items li {
    padding-bottom: 3px;
  }

  .footer-detail-contents {
    text-align: center;
    padding: 10px;
  }

  /**
   * page2
   */
  .pg2-title {
    font-size: 28px;
    margin-bottom: 40px;
  }

  /**
   * プレスリリース
   */
  .press-wrapper .press-contents .press-contents-title {
    font-size: 20px;
    line-height: normal;
  }

  .press-wrapper .press-contents {
    text-align: center;
  }

  .press-wrapper .press-contents .press-item {
    flex-direction: column;
  }

  .press-wrapper .press-contents .press-item .press-item-left,
  .press-wrapper .press-contents .press-item .press-item-right {
    width: 90%;
    margin: 20px auto;
  }

  /**
   * 会社概要
   */
  .about-company-wrapper .about-company-header ul {
    flex-direction: column;
    text-align: center;
  }

  .about-company-wrapper .about-company-header ul li:after,
  .about-company-wrapper .about-company-header ul li:last-child:after {
    width: 80%;
    height: 1px;
    bottom: 0;
    top: auto;
    left: 0;
    margin: 0 auto;
  }

  .about-company-wrapper .about-company-text {
    font-size: 14px;
  }

  .about-company-wrapper .about-company-contents .items {
    flex-direction: column;
    padding: 20px;
  }

  .about-company-wrapper .about-company-contents .items .box {
    width: 100%;
  }

  /**
   * 各業界
   */
  .business-wrapper .items {
    flex-direction: column;
  }

  .business-wrapper .items .item {
    width: 100%;
    margin: 10px auto;
  }

  /**
 * service
 */
  .service-wrapper .items {
    flex-direction: column;
  }

  .service-wrapper .items .item {
    width: 100%;
    margin: 10px auto;
  }

  .service-company-wrapper .items {
    flex-direction: column;
  }

  .service-company-wrapper .items .item {
    width: 100%;
    margin: 10px auto;
  }

  .service-wrapper-dx-solution .items-left {
    flex-direction: column;
  }

  .service-wrapper-dx-solution .items .item-left {
    width: 100%;
    margin: 10px auto;
    padding: 0;
  }

  .service-wrapper-dx-solution .items-right {
    flex-direction: column;
  }

  .service-wrapper-dx-solution .items .item-right {
    width: 100%;
    margin: 10px auto;
  }

  .service-wrapper-dx-main .service-container {
    width: 100%;
    margin: 0 auto;
  }

  .service-wrapper-dx-main .items {
    flex-direction: column;
  }

  .service-wrapper-dx-main .items .item {
    width: 100%;
    margin: 10px auto;
  }

  .service-wrapper-dx-main .items .item .service-title {
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    padding: 10px;
    line-height: 25px;
  }

  /**
   * 機能
   */
  .function-wrapper .items .item {
    width: 47%;
  }

  /**
   * 導入事例
   */
  .example-wrapper .example-contents .items {
    flex-direction: column;
  }

  .example-wrapper .example-contents .items .item {
    width: 100%;
    margin: 10px auto;
  }

  /**
   * 費用
   */
  .price-wrapper .price-contents .price-recommend-item .price-recommend {
    width: 90%;
  }

  .price-wrapper .price-contents .items {
    flex-direction: column;
  }

  .price-wrapper .price-contents .items .item {
    width: 100%;
    margin: 10px auto;
  }

  /**
   * 予約管理
   */
  .reserv-manage-wrapper .items {
    flex-direction: column;
  }

  .reserv-manage-wrapper .items .item {
    width: 100%;
    margin: 10px auto;
  }
}


.Form {
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
}
@media screen and (max-width: 480px) {
  .Form {
    margin-top: 40px;
  }
}
.Form-Item-Top {
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: -70px;
}
.Form-Item {
  border-top: 1px solid #ddd;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .Form-Item {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 16px;
    padding-bottom: 16px;
    flex-wrap: wrap;
  }
  .Form-Item-Top {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 16px;
    padding-bottom: 16px;
    flex-wrap: wrap;
    margin-top: -40px;
  }
}

.Form-Item-Label {
  width: 100%;
  max-width: 248px;
  letter-spacing: 0.05em;
  font-weight: bold;
  font-size: 18px;
}
@media screen and (max-width: 480px) {
  .Form-Item-Label {
    max-width: inherit;
    display: flex;
    align-items: center;
    font-size: 15px;
  }
}
.Form-Item-Label.isMsg {
  margin-top: 8px;
  margin-bottom: auto;
}
@media screen and (max-width: 480px) {
  .Form-Item-Label.isMsg {
    margin-top: 0;
  }
}
.Form-Item-Label-Required {
  border-radius: 6px;
  margin-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 48px;
  display: inline-block;
  text-align: center;
  background: deepskyblue;
  color: #fff;
  font-size: 14px;
}
@media screen and (max-width: 480px) {
  .Form-Item-Label-Required {
    border-radius: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: 32px;
    font-size: 10px;
  }
}
.Form-Item-Input {
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-left: 40px;
  padding-left: 1em;
  padding-right: 1em;
  height: 48px;
  flex: 1;
  width: 100%;
  max-width: 410px;
  background: white;
  font-size: 18px;
}

.Form-Item-Email {
  border-radius: 6px;
  margin-left: 40px;
  padding-left: 1em;
  padding-right: 1em;
  height: 48px;
  flex: 1;
  width: 100%;
  max-width: 410px;
  font-size: 18px;
}
@media screen and (max-width: 480px) {
  .Form-Item-Input {
    margin-left: 0;
    margin-top: 18px;
    height: 40px;
    flex: inherit;
    font-size: 16px;
  }

  .Form-Item-Email {
    margin-left: 0;
    margin-top: 18px;
    height: 40px;
    flex: inherit;
    font-size: 16px;
  }
}

.error{
  display: flex;
  flex-direction: column;
  padding-left: 14px;
  margin-top: -10px;
}

.Form-Item-Textarea {
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-left: 40px;
  padding-left: 1em;
  padding-right: 1em;
  height: 216px;
  flex: 1;
  width: 100%;
  max-width: 410px;
  background: white;
  font-size: 18px;
}
@media screen and (max-width: 480px) {
  .Form-Item-Textarea {
    margin-top: 18px;
    margin-left: 0;
    height: 200px;
    flex: inherit;
    font-size: 16px;
  }
}
.Form-Btn {
  border-radius: 6px;
  margin-top: 22px;
  margin-bottom: 22px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 240px;
  display: block;
  letter-spacing: 0.05em;
  background: deepskyblue;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}
@media screen and (max-width: 480px) {
  .Form-Btn {
    margin-top: 2px;
    margin-bottom: 22px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 160px;
    font-size: 16px;
  }
}

.form-top {
  margin-top: 120px;
  margin-bottom: 70px;
}

.pg2-title-contact {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  position: relative;
  margin-bottom: 30px;
}